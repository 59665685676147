package tta.destinigo.talktoastro.feature_profile.chat_history.data.api

import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.takeFrom
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.feature_profile.chat_history.data.request.ChatHistoryDetailRequest
import tta.destinigo.talktoastro.feature_profile.chat_history.data.request.ChatHistoryListRequest
import tta.destinigo.talktoastro.feature_profile.chat_history.data.request.RequestReviewSubmit
import tta.destinigo.talktoastro.feature_profile.chat_history.data.response.ChatConversationData
import tta.destinigo.talktoastro.feature_profile.chat_history.data.response.ChatConversationResponse
import tta.destinigo.talktoastro.feature_profile.chat_history.data.response.ChatHistoryListData
import tta.destinigo.talktoastro.feature_profile.chat_history.data.response.ChatHistoryListResponse
import tta.destinigo.talktoastro.shared.data.CommonResponse
import tta.destinigo.talktoastro.shared.remote.KtorApi
import tta.destinigo.talktoastro.shared.remote.Resources

class ChatHistoryApi : KtorApi() {
    companion object {
        private const val CHAT_PREFIX = "chat/"
        const val GET_CHAT_LIST = CHAT_PREFIX + "list"
        const val DELETE_CHAT_MSG = CHAT_PREFIX + "delete-history"
        const val GET_CHAT_CONVERSATION = CHAT_PREFIX + "view-history"
        const val POST_SAVE_REVIEW_API = "save-review"
    }

    private suspend fun getAllChatHistory(request: ChatHistoryListRequest) = client.get {
        url {
            takeFrom(GET_CHAT_LIST)
            // Append query parameters here
            parameters.append("page", request.page.toString())
        }
       // parameters.append("page", request.page.toString())
       // setBody(request)
    }.body<ChatHistoryListResponse>()


    private suspend fun DeleteChat(chat_it:String) = client.post {
        url {
            takeFrom(DELETE_CHAT_MSG)
            parameters.append("chat_id", chat_it)
        }
    }.body<CommonResponse>()


    private suspend fun getChatHistoryConversation(request: ChatHistoryDetailRequest) = client.post {
        url(GET_CHAT_CONVERSATION)
        setBody(request)
    }.body<ChatConversationResponse>()

    private suspend fun submitExpertReviewApi(request: RequestReviewSubmit) = client.post {
        url(POST_SAVE_REVIEW_API)
        setBody(request)
    }.body<CommonResponse>()

//    private suspend fun getAllChatHistory(request: ChatHistoryListRequest): HttpResponse {
//        return client.get {
//            url(GET_CHAT_LIST)
//            setBody(request)
//            header(HttpHeaders.ContentType, ContentType.Application.Json)
//            header(HttpHeaders.Authorization, "Bearer <your-token-here>")
//        }.also { response ->
//            Logger.d { "Full response: ${response.status} - ${response.bodyAsText()}" }
//        }
//    }



    suspend fun getAllChatHistoryList(request: ChatHistoryListRequest): Resources<ChatHistoryListData> {
        return withContext(Dispatchers.Default) {
            try {
                val response = getAllChatHistory(request)
                if (response.status.not()) {
                    return@withContext Resources.Error(
                        response.message ?: "Unexpected Error"
                    )
                }
                return@withContext Resources.Success(response.data)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


     suspend fun performDeleteChatMessage(chat_id: String):Resources<Boolean>{
        return  withContext(Dispatchers.Default){
            try {
                val response = DeleteChat(chat_id)
                if(response.status.not() == true){
                  return@withContext Resources.Error(response.msg ?: "Unexpected Error" )
                }
                return@withContext  Resources.Success(response.status)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun loadChatConversationData(request: ChatHistoryDetailRequest): Resources<ChatConversationData> {
        return withContext(Dispatchers.Default){
            try {
                val response = getChatHistoryConversation(request)
                if(response.status.not()){
                    return@withContext Resources.Error(response.message ?: "Unexpected Error" )
                }
                return@withContext  Resources.Success(response.chatConversationData)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun postSubmitExpertReview(request: RequestReviewSubmit):Resources<Boolean>{
        return withContext(Dispatchers.Default){
            try {
                val submitExpertReviewResponse = submitExpertReviewApi(request)
                if(submitExpertReviewResponse.status.not()){
                    return@withContext Resources.Error(msg = submitExpertReviewResponse.msg ?: "Unexpected Error")
                }
                return@withContext  Resources.Success(submitExpertReviewResponse.status)
            }catch (e: ClientRequestException){
                return@withContext  Resources.Error(msg = "Unexpected Error ${e.message}")
            }catch (e: ServerResponseException){
                return@withContext  Resources.Error(msg = "Unexpected Error ${e.message}")
            }catch (e: Exception){
                return@withContext  Resources.Error(msg = "Unexpected Error ${e.message}")
            }
        }
    }

//    suspend fun getAllChatHistoryList(request: ChatHistoryListRequest): Resources<ChatHistoryListData> {
//        return withContext(Dispatchers.Default) {
//            try {
//                val response = getAllChatHistory(request)
//
//                if (!response.status.isSuccess()) {
//                    return@withContext Resources.Error("Failed with status: ${response.status}")
//                }
//
//                val responseBody = response.body<ChatHistoryListResponse>()
//                if (responseBody.status.not()) {
//                    return@withContext Resources.Error(responseBody.message ?: "Unexpected Error")
//                }
//
//                return@withContext Resources.Success(responseBody.data)
//            } catch (e: ClientRequestException) {
//                Resources.Error("Client request error: ${e.response.status.description}")
//            } catch (e: ServerResponseException) {
//                Resources.Error("Server response error: ${e.response.status.description}")
//            } catch (e: Exception) {
//                Resources.Error("Unexpected error: ${e.message}")
//            }
//        }
//    }

}