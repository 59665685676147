package tta.destinigo.talktoastro.feature_profile.chat_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatConcern(
//    0-concern Image
//1- Normal Msg
//2- Concern Answer
//3- Concern Question
//4- Normal Image
    @SerialName("id") val id: Int, // 113012
    @SerialName("message") val message: String, // Library Management System
    @SerialName("msg_type") val msgType: String, // 3
    @SerialName("sent_at") val sentAt: String, // 2023-11-06 15:42:10
    @SerialName("type") val type: String?, // null
    @SerialName("concern_images") val concernImage:List<String>?
)

