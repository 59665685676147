package tta.destinigo.talktoastro.feature_profile

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_profile.component.MoreScreenEvent
import tta.destinigo.talktoastro.feature_profile.component.MoreScreenTabComponent
import tta.destinigo.talktoastro.feature_profile.sign_out.SignOutDialog
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.chat_icon_medium
import tta.destinigo.talktoastro.resources.coin_balance
import tta.destinigo.talktoastro.resources.ic_help_center
import tta.destinigo.talktoastro.resources.ic_next
import tta.destinigo.talktoastro.resources.ic_pricing
import tta.destinigo.talktoastro.resources.ic_privacy_policy
import tta.destinigo.talktoastro.resources.ic_reload
import tta.destinigo.talktoastro.resources.ic_sign_out
import tta.destinigo.talktoastro.resources.ic_term_service
import tta.destinigo.talktoastro.resources.ic_wallet
import tta.destinigo.talktoastro.resources.profile_edit_icon
import tta.destinigo.talktoastro.resources.purchase_histor_bag
import tta.destinigo.talktoastro.resources.round_person_4_24
import tta.destinigo.talktoastro.resources.toolbar_heart
import tta.destinigo.talktoastro.shared.local.TokenProvider.getNewToken
import tta.destinigo.talktoastro.shared.local.UserInfoProvider
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.clearData
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.getFullName
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.getImage
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.getMobileCode
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.getPhone
import tta.destinigo.talktoastro.shared.local.openPlayStore
import tta.destinigo.talktoastro.shared.persentation.SampleDialog
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.initializeCurrency
import tta.destinigo.talktoastro.shared.theme.primaryColor
import tta.destinigo.talktoastro.shared.theme.user_app_play_store_url
import tta.destinigo.talktoastro.shared.theme.user_profile_image_tint


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun MoreScreenTab(component: MoreScreenTabComponent, login: Boolean) {
    val showPrivacyPolicy = remember { mutableStateOf(false) }
    val openDialog = remember { mutableStateOf(false) }
    val openSignOutDialog = remember { mutableStateOf(false) }

    // Show the dialog if openDialog is true
    if (openDialog.value) {
        SampleDialog(
            onDismissRequest = { openDialog.value = false },
            onPlayStore = {
                openSignOutDialog.value = false
                openPlayStore(user_app_play_store_url)
                //showToast("Place Store")
            },
//            onAppStore = {
//                showToast("App Store")
//            }
        )
    }

    if (openSignOutDialog.value) {
        SignOutDialog(
            onDismissRequest = { openSignOutDialog.value = false },
            onSignOutConfirm = {
                clearData()
                openSignOutDialog.value = false
                component.event(MoreScreenEvent.onSignOutButtonClick)
            }
        )
    }

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_COMPACT
    }

    Scaffold(
        topBar = {},
        content = { paddingValues ->
            Box(
                Modifier
                    .fillMaxSize()
                    // .padding(vertical = 30.dp, horizontal = Scale.HORIZONTAL_PADDING)
                    // .padding(start = extraSpace, end = extraSpace)
                    .background(background_color)
                    .padding(paddingValues),
                    contentAlignment = Alignment.Center
            ) {

                Box(widthModifier.fillMaxSize().background(background_color),contentAlignment = Alignment.Center) {
                    if (login) {
                        LazyColumn(
                            modifier = Modifier
                                .fillMaxSize()
                            //.padding(12.dp)
                        ) {
                            item { ProfileScreen(component) }
                            item { Spacer(modifier = Modifier.height(10.dp)) }
                            item { WalletContent(component) }
                            item { Spacer(modifier = Modifier.height(10.dp)) }
                            item {
                                ClickableItem(
                                    Modifier.fillMaxWidth(),
                                    Res.drawable.ic_reload,
                                    "Transaction History",
                                    onClick = {
                                        component.event(MoreScreenEvent.onTransactionHistoryScreenClick)
                                        //openDialog.value = true
                                    })
                            }
                            item {
                                ClickableItem(
                                    Modifier.fillMaxWidth(),
                                    Res.drawable.chat_icon_medium,
                                    "Chat History",
                                    onClick = {
//                                        openDialog.value = true
                                        component.event(MoreScreenEvent.onChatHistoryClick)
                                    })
                            }

                            item {
                                ClickableItem(
                                    Modifier.fillMaxWidth(),
                                    Res.drawable.purchase_histor_bag,
                                    "Purchase History",
                                    onClick = {
//                                        openDialog.value = true
                                        component.event(MoreScreenEvent.onPurchaseHistoryClick)
                                    })
                            }
                            item {
                                ClickableItem(
                                    Modifier.fillMaxWidth(),
                                    Res.drawable.toolbar_heart,
                                    "My Favourites",
                                    onClick = {
                                        openDialog.value = true
                                    })
                            }
                            item {
                                ClickableItem(
                                    Modifier.fillMaxWidth(),
                                    Res.drawable.ic_pricing,
                                    "Refer & Earn",
                                    onClick = {
                                        component.event(MoreScreenEvent.onReferAndEarnClick)
                                       // openDialog.value = true
                                    })
                            }
                            item {
                                ClickableItem(
                                    Modifier.fillMaxWidth(),
                                    Res.drawable.ic_term_service,
                                    "Terms of Service",
                                    onClick = {
                                        component.event(MoreScreenEvent.onTermAndConditionClick)
                                    })
                            }
                            item {
                                ClickableItem(
                                    Modifier.fillMaxWidth(),
                                    Res.drawable.ic_privacy_policy,
                                    "Privacy Policy",
                                    onClick = {
                                        component.event(MoreScreenEvent.onPricayPolicyClick)
                                    })
                            }
                            item {
                                ClickableItem(
                                    Modifier.fillMaxWidth(),
                                    Res.drawable.ic_help_center,
                                    "Help Center",
                                    onClick = {
                                        openDialog.value = true
                                    })
                            }
                            item {
                                ClickableItem(
                                    Modifier.fillMaxWidth(),
                                    Res.drawable.ic_sign_out,
                                    "Sign Out",
                                    onClick = {
                                        openSignOutDialog.value = true
                                        Logger.d { "SignOutDialog ${getNewToken()}" }
                                        //openDialog.value = true
                                        //component.event(MoreScreenEvent.onSignOutButtonClick)
                                    })
                            }
                            item {
                                Row(
                                    modifier = Modifier.fillMaxWidth()
                                        .padding(top = 15.dp, bottom = 15.dp),
                                    verticalAlignment = Alignment.CenterVertically,
                                    horizontalArrangement = Arrangement.Center
                                ) {
                                    // Box aligning content in the center vertically
                                    Box(
                                        modifier = Modifier
//                                        .padding(horizontal = 8.dp)
//                                        .background(Color.LightGray) // Optional: Add background for visibility
//                                        .padding(8.dp)
                                    ) {
                                        // Center the content inside the Box
                                        AppVersion("3.1.56(123)")
                                    }
                                }
                            }
                        }
                    } else {
                        Column(
                            Modifier.fillMaxSize(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            ProfilePicture()
                            Text(text = "Set-Up Account", Modifier.padding(top = 10.dp))
                            Text(text = "Ask experts free in Live Session")
                            Row(
                                Modifier.fillMaxWidth(),
                                horizontalArrangement = Arrangement.Center,
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                SignInButton()
                                Text(
                                    text = "OR",
                                    Modifier.padding(horizontal = 10.dp)
                                )
                                RegisterButton()
                            }
                            Column(
                                Modifier
                                    .fillMaxWidth()
                                    .padding(top = 50.dp),
                                horizontalAlignment = Alignment.CenterHorizontally
                            ) {
                                TermsOfServices(
                                    Modifier.fillMaxWidth(),
                                    onClick = { component.event(MoreScreenEvent.onTermAndConditionClick) })
                                PrivacyPolicy(
                                    Modifier.fillMaxWidth(),
                                    onClick = { component.event(MoreScreenEvent.onPricayPolicyClick) })
                                HelpCenter(Modifier.fillMaxWidth(), onClick = {})
                            }
                            Row(
                                modifier = Modifier.fillMaxWidth()
                                    .padding(top = 15.dp, bottom = 35.dp),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.Center
                            ) {
                                // Box aligning content in the center vertically
                                Box(
                                    modifier = Modifier
                                    //.padding(horizontal = 8.dp)
                                    //.background(Color.LightGray) // Optional: Add background for visibility
                                    //.padding(8.dp)
                                ) {
                                    // Center the content inside the Box
                                    AppVersion("3.1.56(123)")
                                }
                            }
                        }
                    }
                }
            }
        }
    )
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun ProfilePicture() {
    val image: Painter = painterResource(Res.drawable.round_person_4_24)
//    val profileImage = getImage()
//    AsyncImage(
//        model = profileImage,
//        contentDescription = null,
//        modifier = Modifier
//            .fillMaxWidth()
//            .wrapContentHeight(),
//        contentScale = ContentScale.FillWidth,
//    )
    Box(
        modifier = Modifier
            .padding(top = 36.dp)
            .size(90.dp)
            .clip(CircleShape)
            .background(Color.White),
        contentAlignment = Alignment.Center
    ) {
        Image(
            painter = image,
            contentDescription = "User Profile",
            contentScale = ContentScale.Crop,
            modifier = Modifier
                .size(86.dp)
                .clip(CircleShape)
        )
    }
}


@Composable
fun SignInButton() {
    Button(
        onClick = {
            // Action on button click
        },
        modifier = Modifier
            .wrapContentWidth()
            .padding(top = 4.dp),
        shape = RoundedCornerShape(5.dp),
        elevation = ButtonDefaults.elevatedButtonElevation(0.dp)
    ) {
        Text(
            text = "Sign In",
            fontSize = 13.sp
        )
    }
}

@Composable
fun RegisterButton() {
    Button(
        onClick = {
            // Action on button click
        },
        modifier = Modifier
            .wrapContentWidth()
            .padding(top = 4.dp),
        shape = RoundedCornerShape(5.dp),
        elevation = ButtonDefaults.elevatedButtonElevation(0.dp)
    ) {
        Text(
            text = "Register",
            fontSize = 13.sp
        )
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun TermsOfServices(modifier: Modifier = Modifier, onClick: () -> Unit) {
    Row(
        modifier
            .clickable(onClick = onClick)
            .padding(horizontal = 12.dp, vertical = 4.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            Modifier
                .size(45.dp)
                .clip(CircleShape)
                .background(Color.White)
                .clickable(onClick = onClick)
        ) {
            Image(
                painter = painterResource(Res.drawable.ic_term_service),
                contentDescription = "Terms of services image",
                modifier = Modifier
                    .padding(8.dp)
                    .align(Alignment.Center)
            )
        }

        Text(
            text = "Terms of services",
            style = TextStyle(fontSize = 16.sp),
            modifier = Modifier
                .weight(1f)
                .padding(start = 8.dp)
        )

        IconButton(
            onClick = {
//                Toast.makeText(ctx, "Terms of services btn click", Toast.LENGTH_SHORT).show()
            },
            Modifier.size(40.dp)
        ) {
            Icon(
                painter = painterResource(Res.drawable.ic_next),
                contentDescription = "Terms of services icon",
                tint = Color.Gray
            )
        }
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun PrivacyPolicy(modifier: Modifier = Modifier, onClick: () -> Unit) {
    Row(
        modifier = modifier
            .clickable(onClick = onClick)
            .padding(horizontal = 12.dp, vertical = 4.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier
                .size(45.dp)
                .clip(CircleShape)
                .background(Color.White)
                .clickable(onClick = onClick)
        ) {
            Image(
                painter = painterResource(Res.drawable.ic_privacy_policy),
                contentDescription = "Privacy Policy image",
                modifier = Modifier
                    .padding(8.dp)
                    .align(Alignment.Center)
            )
        }

        Text(
            text = "Privacy Policy",
            style = TextStyle(fontSize = 16.sp),
            modifier = Modifier
                .weight(1f)
                .padding(start = 8.dp)
        )

        IconButton(
            onClick = {
//                Toast.makeText(ctx, "Privacy Policy btn click", Toast.LENGTH_SHORT).show()
            },
            modifier = Modifier.size(40.dp)
        ) {
            Icon(
                painter = painterResource(Res.drawable.ic_next),
                contentDescription = "Privacy Policy icon",
                tint = Color.Gray
            )
        }
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun HelpCenter(modifier: Modifier = Modifier, onClick: () -> Unit) {
    Row(
        modifier = modifier
            .clickable(onClick = onClick)
            .padding(horizontal = 12.dp, vertical = 4.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier
                .size(45.dp)
                .clip(CircleShape)
                .background(Color.White)
                .clickable(onClick = onClick)
        ) {
            Image(
                painter = painterResource(Res.drawable.ic_help_center),
                contentDescription = "Help Center image",
                modifier = Modifier
                    .padding(8.dp)
                    .align(Alignment.Center)
            )
        }

        Text(
            text = "Help Center",
            style = TextStyle(fontSize = 16.sp),
            modifier = Modifier
                .weight(1f)
                .padding(start = 8.dp)
        )

        IconButton(
            onClick = {
//                Toast.makeText(ctx, "Help Center btn click", Toast.LENGTH_SHORT).show()
            },
            modifier = Modifier.size(40.dp)
        ) {
            Icon(
                painter = painterResource(Res.drawable.ic_next),
                contentDescription = "Help Center icon",
                tint = Color.Gray
            )
        }
    }
}

@Composable
fun AppVersion(appVersion: String) {
    Text(text = "V- $appVersion", Modifier.padding(top = 20.dp), color = Color.Black)
}


// LOGIN USER

//handler: CommonEventHandler
//@OptIn(ExperimentalResourceApi::class)
//@Composable
//fun ProfileScreen(component: MoreScreenTabComponent) {
//    val profileImage = getImage()
//    val userNUmber= getPhone()
//    val mobileCode = getMobileCode()
//    val fullName =  getFullName()
//    Column() {
//        Row(
//            modifier = Modifier.fillMaxWidth().background(Color.Transparent),
//            verticalAlignment = Alignment.CenterVertically,
//            horizontalArrangement = Arrangement.SpaceBetween
//        ) {
//            Spacer(modifier = Modifier.weight(1f))
//
//            Button(
//                onClick = { component.event(MoreScreenEvent.onClickEditPrifile) },
//                modifier = Modifier
//                    .padding(end = 8.dp)
//                    .background(Color.Transparent),
//                colors = ButtonDefaults.buttonColors(
//                    containerColor = Color.White,
//                   // contentColor = Color.White
//                )
//            ) {
//                Row(verticalAlignment = Alignment.CenterVertically) {
//                    Text(
//                        text = "Edit",
//                        fontSize = 12.sp,
//                       //color = Color(0xFF0A91DD) // Change to your desired text color
//                        color = md_them_primary // Change to your desired text color
//                    )
//                    Icon(
//                        painter = painterResource(Res.drawable.profile_edit_icon),
//                        contentDescription = "Edit icon",
//                        modifier = Modifier.padding(start = 4.dp),
//                        tint = md_them_primary
//                    )
//                }
//            }
//
//        }
//
//        Spacer(modifier = Modifier.height(16.dp))
//
//        Box(
//            modifier = Modifier.fillMaxWidth(),
//            contentAlignment = Alignment.Center
//        ) {
//            Image(
//                painter = painterResource(Res.drawable.round_person_4_24),
//                contentDescription = null,
//                modifier = Modifier
//                    .size(90.dp)
//                    .clip(shape = CircleShape)
//                    .padding(2.dp),
//                colorFilter = ColorFilter.tint(user_profile_image_tint)
//                //colorFilter = ColorFilter.tint(Color(0xFFEEEEEE))
//            )
//        }
//
//        Spacer(modifier = Modifier.height(8.dp))
//
//        Box(
//            modifier = Modifier.fillMaxWidth(),
//            contentAlignment = Alignment.Center
//        ) {
//            Text(
//                text = fullName,
//                maxLines = 1,
//                color = Color.Black
//            )
//        }
//
//        Spacer(modifier = Modifier.height(8.dp))
//
//        Box(
//            modifier = Modifier.fillMaxWidth(),
//            contentAlignment = Alignment.Center
//        ) {
//            Text(
//                text = mobileCode + userNUmber,
//                color = Color.Gray.copy(alpha = 0.5f)
//            )
//        }
//    }
//}

@Composable
fun ProfileScreen(component: MoreScreenTabComponent) {
    val profileImage = getImage()
    val userNumber = getPhone()
    val mobileCode = getMobileCode()
    val fullName = getFullName()

    Column {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .background(Color.Transparent)
                .padding(vertical = 20.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            // Back Button
            IconButton(
                onClick = { component.event(MoreScreenEvent.onBackButtonClick) },
                modifier = Modifier.background(Color.Transparent).padding(start = 10.dp)
            ) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                    contentDescription = "Back icon",
                    tint = Color.Black,
                    modifier = Modifier.size(30.dp)
                )
            }

            // Edit Button
            Button(
                onClick = { component.event(MoreScreenEvent.onClickEditPrifile) },
                modifier = Modifier
                    .background(Color.Transparent).padding(end = 10.dp),
                colors = ButtonDefaults.buttonColors(
                    containerColor = Color.White
                )
            ) {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Text(
                        text = "Edit",
                        fontSize = 12.sp,
                        color = primaryColor
                    )
                    Icon(
                        painter = painterResource(Res.drawable.profile_edit_icon),
                        contentDescription = "Edit icon",
                        modifier = Modifier.padding(start = 4.dp),
                        tint = primaryColor
                    )
                }
            }
        }

        Spacer(modifier = Modifier.height(16.dp))
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        )
        {
            Box(
                modifier = Modifier.size(95.dp)
                    .background(Color.White, CircleShape),
                contentAlignment = Alignment.Center
            ) {
                Image(
                    painter = painterResource(Res.drawable.round_person_4_24),
                    contentDescription = null,
                    modifier = Modifier
                        .size(90.dp)
                        .clip(shape = CircleShape)
                        .padding(2.dp),
                    colorFilter = ColorFilter.tint(user_profile_image_tint)
                )
            }
        }

        Spacer(modifier = Modifier.height(8.dp))

        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            Text(
                text = fullName,
                maxLines = 1,
                color = Color.Black
            )
        }

        Spacer(modifier = Modifier.height(8.dp))

        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            Text(
                text = mobileCode + userNumber,
                color = Color.Gray.copy(alpha = 0.5f)
            )
        }
    }
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun WalletContent(component: MoreScreenTabComponent) {
    Row(
        modifier = Modifier.wrapContentHeight().fillMaxWidth()
            .padding(start = 10.dp, end = 10.dp),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        // State for dialog visibility
        val openDialog = remember { mutableStateOf(false) }

        // Show the dialog if openDialog is true
        if (openDialog.value) {
            SampleDialog(
                onDismissRequest = { openDialog.value = false },
                onPlayStore = {
                    openDialog.value = false
                    openPlayStore(user_app_play_store_url)
                },
//                onAppStore = {
//                    showToast("App Store")
//                }
            )
        }
        // BalanceItem composable
        BalanceItem(
            modifier = Modifier.weight(1f),
            iconResId = Res.drawable.coin_balance,
            title = "Wallet Coin",
            value = (UserInfoProvider.getTotalCoin() ?:"0").toString(),
            iconTint = Color(0xFFF2BC36),
            onClick = {
                openDialog.value = true
            }
        )
        BalanceItem(
            modifier = Modifier.weight(1f),
            iconResId = Res.drawable.ic_wallet,
            title = "Wallet Balance",
            value = "${initializeCurrency()} ${UserInfoProvider.getTotalWalletMoney()}",
            iconTint = primaryColor,
            onClick = {
                // component.event(MoreScreenEvent.onClickWalletBlance)
                //openDialog.value = true
                component.event(MoreScreenEvent.onWalletButtonClick)
            }
        )
    }

}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun BalanceItem(
    modifier: Modifier = Modifier,
    iconResId: DrawableResource,
    title: String,
    value: String,
    iconTint: Color,
    onClick: () -> Unit
) {
    Box(
        modifier = modifier
            .padding(horizontal = 4.dp)
            .background(
                color = Color.White,
                shape = RoundedCornerShape(8.dp),
            )
            .clickable(onClick = onClick)
            .padding(12.dp)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                painter = painterResource(iconResId),
                contentDescription = null,
                modifier = Modifier.size(35.dp),
                colorFilter = ColorFilter.tint(iconTint)
            )
            Column(
                modifier = Modifier.padding(start = 8.dp),
                verticalArrangement = Arrangement.Center,
            ) {
                Text(
                    text = title,
                    fontSize = 12.sp,
                    color = Color.Black
                )
                Text(
                    text = value,
                    fontWeight = FontWeight.Bold,
                    color = Color.Black
                )
            }
        }
    }
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun ClickableItem(
    modifier: Modifier = Modifier,
    iconResId: DrawableResource,
    text: String,
    onClick: () -> Unit
) {
    Row(
        modifier = modifier
            .clickable(onClick = onClick)
            .padding(horizontal = 12.dp, vertical = 4.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            Modifier
                .size(45.dp)
                .clip(CircleShape)
                .background(Color.White)
                .clickable(onClick = onClick)
        ) {
            Image(
                painter = painterResource(iconResId),
                contentDescription = text,
                modifier = Modifier
                    .padding(8.dp)
                    .align(Alignment.Center),
                colorFilter = ColorFilter.tint(primaryColor)
            )
        }

        Text(
            text = text,
            style = TextStyle(fontSize = 16.sp),
            color = Color.Black,
            modifier = Modifier
                .weight(1f)
                .padding(start = 8.dp)
        )

        IconButton(
            onClick = onClick,
            modifier = Modifier.size(40.dp)
        ) {
            Icon(
                painter = painterResource(Res.drawable.ic_next),
                contentDescription = "$text icon",
                tint = Color.Gray
            )
        }
    }
}
