package tta.destinigo.talktoastro.feature_profile.chat_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatConversationData(
    @SerialName("chats") val chats: Chats?,
    @SerialName("chatConcern") val chatConcern: List<ChatConcern>
)
