package tta.destinigo.talktoastro.feature_profile.sign_out

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.shared.local.TokenProvider.clearTokens
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.clearData
import tta.destinigo.talktoastro.shared.persentation.CommonButton


@Composable
fun SignOutDialog(
    onDismissRequest: () -> Unit,
    onSignOutConfirm: () -> Unit
) {
    AlertDialog(
        onDismissRequest = onDismissRequest,
        title = {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(8.dp)
            ) {
                Text(
                    text = "Sign Out",
                    style = MaterialTheme.typography.titleLarge,
                    color = MaterialTheme.colorScheme.onSurface
                )
                IconButton(onClick = onDismissRequest) {
                    Icon(Icons.Filled.Close, contentDescription = "Close")
                }
            }
        },
        text = {
            Text(
                "Are you sure you want to sign out?",
                style = MaterialTheme.typography.bodyMedium,
                color = MaterialTheme.colorScheme.onSurface,
                textAlign = TextAlign.Center,
                modifier = Modifier.padding(horizontal = 16.dp)
            )
        },
        confirmButton = {
            CommonButton(
                "Sign Out",
                modifier = Modifier.padding(8.dp),
                onClick = {
                    onSignOutConfirm.invoke()
                    clearData()
                    clearTokens()
                }
            )
        },
        dismissButton = {
            CommonButton(
                "Cancel",
                modifier = Modifier.padding(8.dp),
                onClick = { onDismissRequest.invoke() },
            )
        },
        containerColor = Color.White,
        shape = RoundedCornerShape(16.dp),
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp)
    )
}
