package tta.destinigo.talktoastro.feature_profile.refer_and_earn.data

import co.touchlab.kermit.Logger
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.client.request.url
import io.ktor.client.statement.HttpResponse
import io.ktor.client.statement.bodyAsText
import io.ktor.http.HttpHeaders
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response.EarningData
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response.MyEarningImageResponse
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response.MyEarningResponse
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response.ReferEarnData
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response.ReferEarnWeeklyWinnersResponse
import tta.destinigo.talktoastro.shared.remote.KtorApi
import tta.destinigo.talktoastro.shared.remote.Resources

class ReferAndEarnApiService : KtorApi() {
    companion object {
        const val ALL_REFERRED_MEMBER = "refer-history"
        const val REFER_AND_EARN_IMAGE = "refer-and-earn-image"
        const val EXPERT_REFER_IMAGE = "expert-referral-image"
        const val TOP_WEEKLY_MEMBER = "get-refer-code"
        const val WALLET_COIN_DETAILS = "checkout-api"
    }

    private suspend fun getWeeklyReferEarn() = client.get {
        url(TOP_WEEKLY_MEMBER)
    }.body<ReferEarnWeeklyWinnersResponse>()

//    @GET(ConstantsHelper.ALL_REFERRED_MEMBER)
//    fun getAllReferredMember(): Call<MyEarningResponse>

    private suspend fun getAllReferredMember() = client.get {
        url(ALL_REFERRED_MEMBER)
    }.body<MyEarningResponse>()


//    @GET(ConstantsHelper.REFER_AND_EARN_IMAGE)
//    suspend fun getReferAndEarnImage(): ResponseBody

//    private suspend fun ReferAndEarnImage(): ByteArray {
//        return client.get {
//            url(REFER_AND_EARN_IMAGE)
//        }.bodyAsChannel().toByteArray()
//    }

    //    private suspend fun ReferAndEarnImage() = client.get {
//        url(REFER_AND_EARN_IMAGE)
//    }.body<MyEarningImageResponse>()
    private suspend fun referAndEarnImage(): MyEarningImageResponse {
        val response: HttpResponse = client.get {
            url(REFER_AND_EARN_IMAGE)
            header(HttpHeaders.Accept, "application/json") // Expecting JSON
        }

        // Read the response as text and parse it as JSON
        val responseBody = response.bodyAsText()
        return Json.decodeFromString(responseBody)
    }


    suspend fun weeklyReferEarn(): Resources<ReferEarnData> {
        return withContext(Dispatchers.Default) {
            try {
                val response = getWeeklyReferEarn()

                if (response.status.not()) {
                    return@withContext Resources.Error(
                        response.msg
                    )
                }

                return@withContext Resources.Success(response.referEarnData)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun AllReferredMember(): Resources<EarningData> {
        return withContext(Dispatchers.Default) {
            try {
                val response = getAllReferredMember()

                if (response.status.not()) {
                    return@withContext Resources.Error(
                        response.msg
                    )
                }
                return@withContext Resources.Success(response.earningData)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

     suspend fun getReferAndEarnImage(): Resources<String> {
        return withContext(Dispatchers.Default) {
            try {
                val response = referAndEarnImage()
                Logger.d { "getReferAndEarnImage $response" }
                if (response.path.isNullOrEmpty()) {
                    return@withContext Resources.Error("Unexpected Error Occurred")
                }
                return@withContext Resources.Success(response.path)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


}