package tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ReferMember(
    @SerialName("user_id")
    val userId:Int?,
    @SerialName("img")
    val img:String?,
    @SerialName("name")
    val name:String,
    @SerialName("date")
    val date:String,
    @SerialName("coin")
    val coin:Int
)
