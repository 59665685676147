package tta.destinigo.talktoastro.feature_profile.edit_profile

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.runtime.remember
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.material3.TopAppBar
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.ui.Alignment
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.ic_person
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_profile.component.EditProfileComponent
import tta.destinigo.talktoastro.feature_profile.component.EditProfileEvent
import tta.destinigo.talktoastro.feature_profile.edit_profile.data.EditProfileRequest
import tta.destinigo.talktoastro.feature_profile.edit_profile.di.EditProfileState
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.getMobileCode
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.getPhone
import tta.destinigo.talktoastro.shared.persentation.CommonOutlineButton
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.under_development_toast


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun EditProfileScreen(component: EditProfileComponent) {
    Scaffold(
        topBar = {
            TopAppBar(
                title = { Text(text = "Edit Profile", color = Color.Black) },
                navigationIcon = {
                    IconButton(
                        onClick = { component.event(EditProfileEvent.OnBackKeyPress) },
                        modifier = Modifier.background(Color.Transparent).padding(start = 10.dp)
                    ) {
                        Icon(imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                            contentDescription = "Back icon",
                            tint = Color.Black,
                            modifier = Modifier.size(30.dp)
                        )
                    }
                },
                colors = TopAppBarDefaults.topAppBarColors(background_color)
            )
        }
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .background(background_color)
//                .padding(horizontal = 16.dp)
//                .padding(vertical = 5.dp)
                .padding(vertical = 30.dp, horizontal = Scale.HORIZONTAL_PADDING),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Spacer(modifier = Modifier.height(16.dp))
            RegisterPage(component)
        }
    }
}

@OptIn(ExperimentalResourceApi::class, ExperimentalMaterial3Api::class)
@Composable
fun RegisterPage(component: EditProfileComponent) {
    val state by component.state.collectAsState()
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .padding(horizontal = 12.dp) // Adjusted horizontal padding
            .padding(top = 80.dp) // Adjusted top padding
            .fillMaxSize()
    ) {

        var name by remember { mutableStateOf("") }
        var email by remember { mutableStateOf("") }

        val emailRegex = Regex("^\\w+([.-]?\\w+)*@\\w+([.-]?\\w+)*(\\.\\w{2,3})+$")

        val isNameValid by remember {
            derivedStateOf {
                name.isNotBlank()
            }
        }

        val isEmailValid by remember {
            derivedStateOf {
                email.isNotBlank() && emailRegex.matches(email)
            }
        }

        Image(
            painter = painterResource(Res.drawable.ic_person),
            contentDescription = null,
            modifier = Modifier
                .size(120.dp)
                .clip(CircleShape),
            alignment = Alignment.Center,
            colorFilter = ColorFilter.tint(Color(0xFF0099DB))
        )
        Spacer(modifier = Modifier.height(5.dp))
        Box(modifier = Modifier.background(Color.LightGray).clickable { }) {
            Text(
                text = "Edit",
                color = Color.Black,
                modifier = Modifier.padding(vertical = 8.dp).clickable {
                    showToast(under_development_toast)
                }
            )
        }

        OutlinedTextField(
            value = name,
            onValueChange = { name = it },
            label = { Text("Name") },
            modifier = Modifier
                .padding(vertical = 8.dp, horizontal = 16.dp)
                .fillMaxWidth(),
            isError = !isNameValid,
            colors = TextFieldDefaults.colors()
        )

        if (!isNameValid) {
            Text(
                text = "Name cannot be empty",
                color = Color.Red,
                modifier = Modifier.padding(start = 16.dp)
            )
        }

        OutlinedTextField(
            value = email,
            onValueChange = { email = it },
            label = { Text("Email") },
            modifier = Modifier
                .padding(vertical = 8.dp, horizontal = 16.dp)
                .fillMaxWidth(),
            isError = !isEmailValid,
            colors = TextFieldDefaults.colors()
        )

        if (!isEmailValid) {
            Text(
                text = "Invalid email address",
                color = Color.Red,
                modifier = Modifier.padding(start = 16.dp)
            )
        }

        Row(modifier = Modifier.align(Alignment.Start).padding(vertical = 8.dp, horizontal = 16.dp)) {
            val userNUmber= getPhone()
            val mobileCode = getMobileCode()
            Text(
                text = "Phone No: ",
                color = Color.Black,
                modifier = Modifier.padding(vertical = 8.dp)
            )
            Text(

                text = mobileCode + userNUmber,
                color = Color.Black,
                modifier = Modifier.padding(vertical = 8.dp)
            )
        }

        Spacer(modifier = Modifier.height(15.dp))

        CommonOutlineButton("Save Changes",Modifier.align(Alignment.End),{
            val editProfileRequest = EditProfileRequest(name, email,"")
            component.event(EditProfileEvent.OnSaveProfileButtonClick(editProfileRequest))
        },null,false)

        when(state){
            is EditProfileState.OnError -> showToast((state as EditProfileState.OnError).message)
            is EditProfileState.OnLoading -> Unit
           //is EditProfileState.OnLoading -> LoadingProgressDialog(state.isLoading)
            is EditProfileState.onSuccess -> showToast("Profile Change Success")
        }
    }
}



@Composable
fun ProgressBar() {
    // Your ProgressBar implementation here
}