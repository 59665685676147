package tta.destinigo.talktoastro.feature_profile.purchase_history

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.feature_profile.purchase_history.data.domain.PurchaseHistoryRepo
import tta.destinigo.talktoastro.feature_profile.purchase_history.data.request.PurchaseHistoryRequest
import tta.destinigo.talktoastro.feature_profile.purchase_history.data.response.PurchaseHistoryData
import tta.destinigo.talktoastro.shared.remote.Resources

class PurchaseHistoryComponent(
    context: ComponentContext,
    private val onBack: () -> Unit,
    private val onPurchaseHistoryCardClick:(String) -> Unit
) : ComponentContext by context, KoinComponent {
    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
        Logger.e { "Coroutine Exception: ${throwable.message}" }
        throwable.printStackTrace()
    }

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
    private val api: PurchaseHistoryRepo by inject()

    fun onCardClick(data: String) = onPurchaseHistoryCardClick.invoke(data)
    private val _state = MutableStateFlow<List<PurchaseHistoryData>>(emptyList())
    val state: StateFlow<List<PurchaseHistoryData>> = _state
    fun onBackClick() = onBack.invoke()
    private val currentPage = MutableStateFlow(1) // Track the current page
    val isLoading = MutableStateFlow(false) // Track loading state

    init {
        lifecycle.doOnStart {
            Logger.d { "Purchase History Screen Component onStart" }
            coroutineScope.launch {
                fetchPage(currentPage.value)
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Purchase History Screen Component  onDestroy" }
            coroutineScope.cancel()
        }
    }

    private suspend fun fetchPage(page: Int) {
      ////  Logger.d { "on fetchPage page $page" }
        if (isLoading.value) return // Prevent multiple requests
        isLoading.value = true
        api.getPurchaseHistoryInfo(PurchaseHistoryRequest(page)).collect { result ->
            when (result) {
                is Resources.Error -> {
                    Logger.e { "Error: ${result.msg}" }
                }

                is Resources.Loading -> {
                    Logger.d { "Loading page $page..." }
                }

                is Resources.Success -> {
                   // Logger.d { "Success ${result.data}" }

                    val currentData = _state.value
                    val updatedData = currentData + (result.data ?: emptyList())
                    _state.emit(updatedData)
                    currentPage.value = page
                }
            }
            isLoading.value = false
        }
    }

    fun loadNextPage() = coroutineScope.launch {
        fetchPage(currentPage.value + 1)
    }
}
