package tta.destinigo.talktoastro.feature_profile.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class ChatDetailsData(
    @SerialName
("chat_id")
    val chatId: Int?,
    @SerialName
("created_at")
    val createdAt: String?,
    @SerialName
("duration")
    val duration: String?,
    @SerialName
("expert_end_chat_date_time")
    val expertEndChatDateTime: String?,
    @SerialName
("expert_id")
    val expertId: Int?,
    @SerialName
("expert_info")
    val expertInfo: ExpertInfo?,
    @SerialName
("expert_price")
    val expertPrice: String?,
    @SerialName
("expert_start_chat_date_time")
    val expertStartChatDateTime: String?,
    @SerialName
("id")
    val id: Int?,
    @SerialName
("paid_amount")
    val paidAmount: String?,
    @SerialName
("remaining_wallet_balance")
    val remainingWalletBalance: String?,
    @SerialName
("status")
    val status: String?,
    @SerialName
("updated_at")
    val updatedAt: String?,
    @SerialName
("user_end_chat_date_time")
    val userEndChatDateTime: String?,
    @SerialName
("user_id")
    val userId: Int?,
    @SerialName
("user_start_chat_date_time")
    val userStartChatDateTime: String?,
    @SerialName
("winyway_price")
    val winywayPrice: String?,
    @SerialName
("user_rating")
    val userRating: String?,
    @SerialName
("user_review")
    val userReview: String?
)
