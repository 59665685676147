package tta.destinigo.talktoastro.feature_profile.edit_profile.domain

import co.touchlab.kermit.Logger
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.feature_profile.edit_profile.data.EditProfileApiService
import tta.destinigo.talktoastro.feature_profile.edit_profile.data.EditProfileRequest
import tta.destinigo.talktoastro.feature_profile.edit_profile.data.ProfileDetails
import tta.destinigo.talktoastro.shared.local.UserInfoProvider
import tta.destinigo.talktoastro.shared.remote.Resources

class EditProfileUserUseCases(private val editProfileApiService: EditProfileApiService){
    suspend fun onEditProfile(request: EditProfileRequest): Flow<Resources<ProfileDetails>> {
        return flow {
            editProfileApiService.onUpdateUserProfile(request).collect { state ->
                Logger.d("returnedFlow -> $state")
                when (state) {
                    is Resources.Error -> emit(Resources.Error(state.msg ?: "Unexpected Error"))
                    is Resources.Loading -> emit(Resources.Loading(state.isLoading))
                    is Resources.Success -> {
                        state.data.let {
                            UserInfoProvider.setFullName(it?.full_name.toString())
                            UserInfoProvider.setEmail(it?.email ?: "")
                            UserInfoProvider.setImage(it?.profileimg.toString())
                            UserInfoProvider.setId(it?.user_id.toString())
                        }
                        emit(Resources.Success(state.data))
                    }
                }
            }
        }
    }
}