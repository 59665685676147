package tta.destinigo.talktoastro.feature_profile.chat_history.data.domain

import tta.destinigo.talktoastro.feature_profile.chat_history.data.api.ChatHistoryApi

class ChatHistoryRepo(private val api: ChatHistoryApi) {
//    fun getChatHistoryInfo(request: ChatHistoryListRequest): Flow<Resources<ChatHistoryListData>>{
//        return flow {
//            emit(Resources.Loading())
//            val response = api.getAllChatHistoryList(request)
//            emit(response)
//            emit(Resources.Loading(false))
//        }
//    }
}