package tta.destinigo.talktoastro.feature_profile.purchase_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PurchaseHistoryData(
    @SerialName("created_at") val createdAt: String?, // 2024-04-26 17:08:38
    @SerialName("custom_order") val customOrder: CustomOrderDetails?,
    @SerialName("custom_order_id") val customOrderId: Int?, // 3
    @SerialName("custom_order_price") val customOrderPrice: String?, // 0
    @SerialName("deleted_at") val deletedAt: String?, // null
    @SerialName("id") val id: Int?, // 3
    @SerialName("paid_amount") val paidAmount: String?, // 0
    @SerialName("updated_at") val updatedAt: String?, // 2024-04-26 17:08:38
    @SerialName("user_id") val userId: Int? // 69
)