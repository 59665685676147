package tta.destinigo.talktoastro.feature_profile.edit_profile.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EditProfileRequest(
    @SerialName("full_name") val fullName:String,
    @SerialName("email") val Email:String,
    @SerialName("profile_image") val profileImage:String,
)