package tta.destinigo.talktoastro.feature_profile.refer_and_earn.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.ReferAndEarnApiService
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response.EarningData
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response.ReferEarnData
import tta.destinigo.talktoastro.shared.remote.Resources

class ReferAndEarnRepo(val api: ReferAndEarnApiService) {

    fun weeklyReferEarn(): Flow<Resources<ReferEarnData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.weeklyReferEarn()
            emit(response)
            emit(Resources.Loading(false))
        }
    }

    fun AllReferredMember(): Flow<Resources<EarningData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.AllReferredMember()
            emit(response)
            emit(Resources.Loading(false))
        }
    }

    //getReferAndEarnImage

    fun getReferAndEarnImage(): Flow<Resources<String>> {
        return flow {
            emit(Resources.Loading())
            val response = api.getReferAndEarnImage()
            emit(response)
            emit(Resources.Loading(false))
        }
    }
}