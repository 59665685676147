package tta.destinigo.talktoastro.feature_profile.transaction_history.persentation.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalClipboardManager
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_profile.transaction_history.data.response.TransactionOrderData
import tta.destinigo.talktoastro.feature_profile.transaction_history.persentation.convertToIndianTime
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_arrow_forward_ios_24
import tta.destinigo.talktoastro.resources.baseline_support_agent_24
import tta.destinigo.talktoastro.resources.ic_wallet
import tta.destinigo.talktoastro.shared.persentation.CopyableTransactionIdWithIcon
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.initializeCurrency
import kotlin.math.pow
import kotlin.math.roundToInt

@Composable
fun PaymentDetailsHeading(
    isVisible: Boolean = false, // control visibility
    onIconClick: () -> Unit = {}, // handle actions if needed
    data: TransactionOrderData
) {
    if (!isVisible) return // If not visible, return early

    Surface(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp)
            .background(Color.White, shape = RoundedCornerShape(Scale.CORNER_MEDIUM))
           // .elevation(4.dp)
            .padding(16.dp),
        //color = MaterialTheme.colors.surface
    ) {
        Column(modifier = Modifier.background(Color.White)) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.padding(bottom = 8.dp)
            ) {
                Box(
                    modifier = Modifier
                        .size(45.dp)
                        //.background(Color.Gray.copy(alpha = 0.5f), shape = CircleShape)
                        .padding(10.dp),
                    contentAlignment = Alignment.Center
                ) {

                    Icon(
                        painter = painterResource(Res.drawable.ic_wallet),
                        contentDescription = "Order Icon",
                        tint = MaterialTheme.colorScheme.primary,
                        modifier = Modifier.size(28.dp)
                    )
//                    Icon(
//                        painter = painterResource(id = R.drawable.ic_wallet),
//                        contentDescription = "Wallet Icon",
//                        tint = Color.Gray
//                    )
                }
                Spacer(modifier = Modifier.width(16.dp))
                Column {
                    Text(
                        text = "Wallet Recharge!",
                        //style = MaterialTheme.typography.subtitle1.copy(fontWeight = FontWeight.Bold)
                    )
                    Text(
                        text = "${data.createdAt?.let { convertToIndianTime(it) }}" ,
                        //style = MaterialTheme.typography.caption
                    )
                }
            }

            Text(
                text = "Recharge Pack",
                //style = MaterialTheme.typography.body2,
                modifier = Modifier.padding(bottom = 4.dp)
            )

            Surface(
                shape = RoundedCornerShape(8.dp),
                color = Color.LightGray.copy(alpha = 0.2f),
                modifier = Modifier
                    .fillMaxWidth()
                    .height(80.dp)
                    .padding(horizontal = 8.dp, vertical = 4.dp)
            ) {
                Box(modifier = Modifier.fillMaxSize()) {
                    Text(
                        text = initializeCurrency(),
                        //style = MaterialTheme.typography.subtitle1,
                        modifier = Modifier.align(Alignment.TopStart).padding(12.dp)
                    )
                    Text(
                        text = "${data.totalAmount}",
                       // style = MaterialTheme.typography.h6,
                        modifier = Modifier.align(Alignment.TopStart).padding(start = 24.dp, top = 12.dp)
                    )
                    Text(
                        text = "${data.planName}",
                        //style = MaterialTheme.typography.subtitle2.copy(fontWeight = FontWeight.Bold),
                        modifier = Modifier.align(Alignment.BottomEnd).padding(12.dp),
                        color = Color.Black
                    )
                }
            }

            Column(modifier = Modifier.padding(top = 16.dp)) {
                TextRow(heading = "Amount", value = "${initializeCurrency()} ${data.totalAmount}")
                TextRow(heading = "Discount", value = "-₹ 180", isVisible = false)
                val serviceTax = data.amountWithGst?.toDouble()?.minus(data.totalAmount?.toDouble()!!)

                TextRow(
                    heading = "Service Tax (18% GST)",
                    value = "${initializeCurrency()}$serviceTax"
                )

                fun Float.roundTo(dec: Int): Double {
                    return (this * 10.0.pow(dec)).roundToInt() / 10.0.pow(dec).toDouble()
                }
//                TextRow(heading = "Service Tax (18% GST)", value = "${data.amountWithGst - $data.totalAmount}")
                data.usedCoinBalance?.toFloat().let{
                        TextRow(
                            heading = "Coin Applied Discount",
                            value = "-${initializeCurrency()}${it?.roundTo(2).toString()}",
                            //textColor = MaterialTheme.colors.primary,
                            isVisible = true
                        )
                }


                Spacer(modifier = Modifier.height(4.dp))
                TextRow(
                    heading = "Total amount paid",
                    value = "${initializeCurrency()} ${data.paidAmount}",
                    //style = MaterialTheme.typography.body1.copy(fontWeight = FontWeight.Bold)
                )
            }

            Spacer(modifier = Modifier.height(16.dp))
            Text(
                text = "Note - It may take up to 24 hours for your wallet to be updated. If your wallet is already updated, please ignore it.",
                textAlign = TextAlign.Center,
                modifier = Modifier.fillMaxWidth()
            )

            SupportButton(onClick = {})

            val clipboardManager = LocalClipboardManager.current
            CopyableTransactionIdWithIcon(data.txnId.toString()) { txnId ->
                clipboardManager.setText(AnnotatedString(txnId))
            }
            Text(
                modifier = Modifier.fillMaxWidth(),
                text = "${data.createdAt?.let { convertToIndianTime(it) }}" ,
                //style = MaterialTheme.typography.caption
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.bodySmall,
                color = MaterialTheme.colorScheme.onSurfaceVariant
            )
        }
    }
}

@Composable
fun SupportButton(onClick: () -> Unit) {
    Surface(
        modifier = Modifier
            .fillMaxWidth()
            .height(56.dp)
            .background(Color.White)
            //.padding(horizontal = 16.dp)
            .clickable(onClick = onClick),
        shape = RoundedCornerShape(Scale.CORNER_MEDIUM),
        color = Color.White,
        shadowElevation = 2.dp // Equivalent to elevation
    ) {
        Row(
            modifier = Modifier.fillMaxSize().background(background_color),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween // Align icons and text
        ) {
            Icon(
                painter = painterResource(Res.drawable.baseline_support_agent_24),
                contentDescription = null,
                tint = Color.Black,
                modifier = Modifier.padding(start = 16.dp)
            )

            Text(
                text = "Contact Winyway Support",
                style = TextStyle(
                    fontWeight = FontWeight.SemiBold,
                    fontSize = 15.sp,
                    color = Color.Black
                ),
                modifier = Modifier.weight(1f), // Center-align the text with spacing
                textAlign = TextAlign.Center
            )

            Icon(
                painter = painterResource(Res.drawable.baseline_arrow_forward_ios_24),
                contentDescription = null,
                tint = Color.Black,
                modifier = Modifier.padding(end = 16.dp)
            )
        }
    }

}

@Composable
fun TextRow(
    heading: String,
    value: String,
    textColor: Color = Color.Black,
   // style: TextStyle = MaterialTheme.typography.body2,
    isVisible: Boolean = true
) {
    if (!isVisible) return
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 4.dp),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            text = heading,
            //style = style,
            modifier = Modifier.padding(start = 8.dp)
        )
        Text(
            text = value,
            //style = style.copy(color = textColor),
            modifier = Modifier.padding(end = 8.dp)
        )
    }
}
