package tta.destinigo.talktoastro.feature_profile


data class PaginationItems<ChatConversationData>(
    val items: List<ChatConversationData> // List of items for the current page
)
expect abstract class BasePagingSource<Value : Any>() {
    // Declare an expected function that platform-specific implementations must provide
    abstract suspend fun fetchData(page: Int, limit: Int): PaginationItems<Value>
}

expect fun openWhatsAppAndRefer(image: String,appUrl:String,website:String,referCode:String)