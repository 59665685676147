package tta.destinigo.talktoastro.feature_profile.refer_and_earn

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response.WeeklyWinners
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.domain.ReferAndEarnRepo
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.setTotalCoin
import tta.destinigo.talktoastro.shared.remote.Resources

class ReferAndEarnScreenComponent(
    context: ComponentContext,
    private  val onBackButtonClick:() -> Unit
): ComponentContext by context, KoinComponent {
    fun onBackClick() = onBackButtonClick.invoke()
    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
        Logger.e { "Coroutine Exception: ${throwable.message}" }
        throwable.printStackTrace()
    }
    private val api: ReferAndEarnRepo by inject()
    //ReferAndEarnRepo
    private val _state = MutableStateFlow<List<WeeklyWinners>>(emptyList())
    val state: StateFlow<List<WeeklyWinners>> = _state
    private val _referAndEarnImageState = MutableStateFlow<String>("")
    val referAndEarnImageState: StateFlow<String> = _referAndEarnImageState

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
    init {

        lifecycle.doOnStart {
            Logger.d { "Refer & Earn Screen Component onStart" }
            coroutineScope.launch {
                getWeeklyReferEarn()
                getAllReferredMember()
                getReferAndEarnImageData()
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Refer & Earn Screen Component onDestroy" }
            coroutineScope.cancel()
        }

    }

    private suspend fun getWeeklyReferEarn(){
        api.weeklyReferEarn().collect{
            when(it){
                is Resources.Error -> {
                    Logger.d { "getWeeklyReferEarn on Error State ${it.msg}" }
                }
                is Resources.Loading -> {
                    Logger.d { "getWeeklyReferEarn on Loading State ${it.isLoading}" }
                }
                is Resources.Success -> {
                    if (it.data?.weeklyWinners?.isEmpty() == true) {
                        Logger.d { "No more data pages to load." }
                    }else {
                        val data = it.data?.weeklyWinners
                        //setTotalCoin()
                        if (data != null) {
                            _state.emit(data)
                        }
                    }
                }
            }
        }
    }

    private suspend fun getAllReferredMember(){
        api.AllReferredMember().collect{
            when(it){
                is Resources.Error -> {
                    Logger.d { "getAllReferredMember on Error State ${it.msg}" }
                }
                is Resources.Loading -> {
                    Logger.d { "getAllReferredMember on Loading State ${it.isLoading}" }
                }
                is Resources.Success -> {
                    it.data?.totalCoin?.let { it1 -> setTotalCoin(it1) }
                    Logger.d { "getAllReferredMember on Success State ${it.data}" }
                }
            }
        }
    }

    private suspend fun getReferAndEarnImageData() {
        api.getReferAndEarnImage().collect {
            when (it) {
                is Resources.Error -> {
                    Logger.d { "getReferAndEarnImageData on Error State ${it.msg}" }
                    _referAndEarnImageState.emit("Unexpected Error")
                }
                is Resources.Loading -> {
                    Logger.d { "getReferAndEarnImageData on Loading State ${it.isLoading}" }
                }
                is Resources.Success -> {
                    Logger.d { "getReferAndEarnImageData on Success State ${it.data}" }
                    it.data?.let { it1 -> _referAndEarnImageState.emit(it1) }
                    val fileName = "refer-and-earn.PNG"
                    //it.data?.let { it1 -> downloadImage(it1, fileName) }
                }
            }
        }
    }

//    fun encodeToBase64(byteArray: ByteArray): String {
//        return Base64.getEncoder().encodeToString(byteArray)
//    }


//    suspend fun downloadImage(imageUrl: String, fileName: String) {
//        val client = HttpClient()
//        try {
//            val response: HttpResponse = client.get(imageUrl)
//            val bytes: ByteArray = response.body()
//
//            saveImageToFile(bytes, fileName)
//            println("Image downloaded successfully: $fileName")
//        } catch (e: Exception) {
//            println("Error downloading image: ${e.message}")
//        } finally {
//            client.close()
//        }
//    }

}