package tta.destinigo.talktoastro.feature_profile

import kotlinx.browser.window
import org.w3c.xhr.BLOB
import org.w3c.xhr.XMLHttpRequest
import org.w3c.xhr.XMLHttpRequestResponseType

actual abstract class BasePagingSource<Value : Any> actual constructor() {
    actual abstract suspend fun fetchData(
        page: Int,
        limit: Int
    ): PaginationItems<Value>

    suspend fun load(page: Int, limit: Int): PaginationItems<Value> {
        return try {
            val response = fetchData(page, limit)
            response
        } catch (e: Exception) {
            throw e
        }
    }
}

actual fun openWhatsAppAndRefer(
    image: String,
    appUrl: String,
    website: String,
    referCode: String
) {
    // Prepare the referral message
    val referralMessage = """
        🎉 Download the TalkToAstro App now and earn coins to enjoy free consultations! 🌟
        Use my referral code: $referCode during registration to claim your rewards.

        🌐 Visit the website for more: $website
        📲 Download the app here: https://play.google.com/store/apps/details?id=tta.destinigo.talktoastro&referrer=utm_source%3D$referCode

        Don't miss out on this amazing opportunity! 🚀
        """.trimIndent()

    // Save the image locally and enable sharing
    saveImageAndPrepareShareForWhatsAppReferral(image, referralMessage)
}

private fun saveImageAndPrepareShareForWhatsAppReferral(imageUrl: String, message: String) {
    val xhr = XMLHttpRequest()
    xhr.open("GET", imageUrl, true)
    xhr.responseType = XMLHttpRequestResponseType.BLOB // Set the response type to 'blob'

    xhr.onload = { _ ->
        if (xhr.status == 200.toShort()) {
            val blob = xhr.response
            // Debugging: Log the response
            console.log("Image Blob received: $blob")

            // After the image is loaded, share it
            shareViaWhatsAppWithImageBlob(blob, message)
        } else {
            console.error("Download failed with status: " + xhr.status)
        }
    }

    xhr.onerror = { _ ->
        console.error("Error fetching the image.")
    }

    xhr.send()
}

private fun shareViaWhatsAppWithImageBlob(blob: dynamic, message: String) {
    // Check if navigator.share is available
    if (js("navigator.canShare")) {
        try {
            // Create a new File object with the blob
            val file = js("new File([blob], 'refer_and_earn.png', { type: 'image/png' })")

            // Check if the browser supports sharing files
            if (js("navigator.canShare({ files: [file] })")) {
                // Prepare the options object for sharing
                val options: dynamic = js("({})")
                options.text = message
                options.files = arrayOf(file) // Pass the file array

                // Debugging: Log the options before sharing
                console.log("Sharing with options: ", options)

                // Share the content
                js("navigator.share(options)").then {
                    console.log("Shared successfully!")
                }.catch { error ->
                    console.error("Error sharing: ", error)
                    fallbackToWhatsApp(message, blob)
                }
            } else {
                console.warn("Browser does not support sharing files. Falling back to WhatsApp.")
                fallbackToWhatsApp(message, blob)
            }
        } catch (e: Throwable) {
            console.error("Error preparing file for sharing: ", e)
            fallbackToWhatsApp(message, blob)
        }
    } else {
        console.warn("navigator.share is not available. Falling back to WhatsApp.")
        fallbackToWhatsApp(message, blob)
    }
}

// Fallback to WhatsApp Web
private fun fallbackToWhatsApp(message: String, blob: dynamic) {
    console.log("Falling back to WhatsApp Web")

    // Convert blob to an Object URL
    val imageUrl = js("URL.createObjectURL(blob)")
    val whatsappUrl = "https://wa.me/?text=${encodeURIComponent(message)}"

    // Notify the user
    window.alert("Sharing via WhatsApp. Text will be shared directly, and you'll need to attach the image manually.")

    // Open WhatsApp with the message text
    js("window.open(whatsappUrl, '_blank')")

    // Provide the image URL for manual download
    window.alert("To share the image, download it using this link: $imageUrl")
}

private fun encodeURIComponent(value: String): String {
    return js("encodeURIComponent(value)") as String
}
