package tta.destinigo.talktoastro.feature_profile.transaction_history.data.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.feature_profile.transaction_history.data.remote.TransactionHistoryApiService
import tta.destinigo.talktoastro.feature_profile.transaction_history.data.response.TransactionOrderData
import tta.destinigo.talktoastro.shared.remote.Resources

class TransactionInfoRepo(
    private val api: TransactionHistoryApiService
) {
    fun getTransactionHistoryInfo():Flow<Resources<List<TransactionOrderData>>>{
        return flow {
            emit(Resources.Loading())
            val response = api.getTransactionHistoryInfo()
            emit(Resources.Success(response.data))
            emit(Resources.Loading(false))
        }
    }
}