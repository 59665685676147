package tta.destinigo.talktoastro.feature_profile.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Section(
    @SerialName("created_at") val createdAt: String, // 2024-04-15 17:26:10
    @SerialName("custom_order_id") val customOrderId: Int, // 1
    @SerialName("expert_id") val expertId: Int, // 200
    @SerialName("id") val id: Int, // 1
    @SerialName("section_name") val sectionName: String, // Section 1
    @SerialName("updated_at") val updatedAt: String, // 2024-04-15 17:26:10
    @SerialName("lessions") val lession: List<Lession>?
)
