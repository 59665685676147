package tta.destinigo.talktoastro.feature_profile.component

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.feature_profile.chat_history.data.api.ChatHistoryApi
import tta.destinigo.talktoastro.feature_profile.chat_history.data.request.ChatHistoryListRequest
import tta.destinigo.talktoastro.feature_profile.chat_history.data.request.RequestReviewSubmit
import tta.destinigo.talktoastro.feature_profile.chat_history.data.response.ChatHistoryListData
import tta.destinigo.talktoastro.shared.data.RechargeData
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.remote.Resources

//class ChatHistoryComponent(
//    context: ComponentContext,
//    private val onBackPress: () -> Unit
//) : ComponentContext by context, KoinComponent {
//
//    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
//        Logger.e { "Coroutine Exception: ${throwable.message}" }
//        throwable.printStackTrace()  // Ensure you print the full stack trace for debugging
//    }
//
//    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
//    private val _state = MutableStateFlow<List<ChatHistoryListData?>>(emptyList()) // Replace ChatHistoryListData with ChatHistoryData as per your model
//    val state: StateFlow<List<ChatHistoryListData?>> = _state
//
//    private val api: ChatHistoryApi by inject()
//
//    // Page number for pagination
//    private var currentPage = 1
//    private var isLastPage = false
//    private var isLoading = false
//
//    fun onBackButtonClick() = onBackPress.invoke()
//
//    init {
//        lifecycle.doOnStart {
//            Logger.d { "Chat History Screen Component onStart" }
//            coroutineScope.launch {
//                loadNextPage()
//            }
//        }
//
//        lifecycle.doOnDestroy {
//            Logger.d { "Chat History Component onDestroy" }
//            coroutineScope.cancel()
//        }
//    }
//
//
//    private suspend fun loadNextPage() {
//        if (isLoading || isLastPage) return
//
//        isLoading = true
//        Logger.d { "Loading page: $currentPage" }
//
//        val request = ChatHistoryListRequest(page = currentPage)
//
//        try {
//            when (val response = api.getAllChatHistoryList(request)) {
//                is Resources.Error -> {
//                    Logger.d { "Error fetching chat history: ${response.msg}" }
//                    isLoading = false
//                }
//                is Resources.Loading -> {
//                    Logger.d { "Loading: ${response.isLoading}" }
//                }
//
//                is Resources.Success -> {
//                    val chatList = response.data?.chatList ?: emptyList()
//                    Logger.d { "Fetched chat history: ${response.data}" }
//
//                    if (chatList.isEmpty()) {
//                        isLastPage = true
//                    } else {
//                        val newChatHistory = _state.value + response.data
//                        _state.value = newChatHistory
//                        currentPage++
//                    }
//                    isLoading = false
//                }
//
//            }
//        } catch (e: Exception) {
//            Logger.e { "Exception occurred while loading chat history: ${e.message}" }
//            isLoading = false
//        }
//    }
//
//
//    fun deleteChatMessage(chat_id: String) {
//        coroutineScope.launch {
//            try {
//                when (val response = api.performDeleteChatMessage(chat_id)) {
//                    is Resources.Error -> {
//                        Logger.d { "deleteChatMessage ${response.msg}" }
//                    }
//                    is Resources.Loading -> {
//                        Logger.d { "deleteChatMessage ${response.isLoading}" }
//                    }
//                    is Resources.Success -> {
//                        onDeleteChatMessageSuccess(response.data!!)
//                        Logger.d { "deleteChatMessage ${response.data}" }
//                    }
//                }
//            } catch (e: Exception) {
//                Logger.e { "Exception occurred while deleting chat message: ${e.message}" }
//            }
//        }
//    }
//
//    private fun onDeleteChatMessageSuccess(data:Boolean){
//        if(data){
//            showToast("Chat Delete Successful")
//        }
//    }
//
//
//    // This method can be called on scroll or a trigger to load more data
//    fun loadMoreChatHistory() {
//        coroutineScope.launch {
//            loadNextPage()
//        }
//    }
//}


class ChatHistoryComponent(
    context: ComponentContext,
    private val onBackPress: () -> Unit,
    private val onChatCardClick: (String, String, String, String) -> Unit,
    private val onLoginClick: () -> Unit,
    private val onLowBalance: () -> Unit,
    private val onChatConsultation: (String, String, String, String, String, String, String) -> Unit,
    private val onRechargeRequired: (RechargeData?) -> Unit,
) : ComponentContext by context, KoinComponent {

    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
        Logger.e { "Coroutine Exception: ${throwable.message}" }
        throwable.printStackTrace()  // Ensure you print the full stack trace for debugging
    }

    private val coroutineScope =
        CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
    private val _state =
        MutableStateFlow<List<ChatHistoryListData>>(emptyList()) // Changed nullable type to non-nullable
    val state: StateFlow<List<ChatHistoryListData>> = _state

    private val api: ChatHistoryApi by inject()


    // Page number for pagination
    private var currentPage = 1
    private var isLastPage = false
    private var isLoading = false
    val context = context

    fun onBackButtonClick() = onBackPress.invoke()
    fun onLoginClick() = onLoginClick.invoke()
    fun onLowBalance() = onLowBalance.invoke()
    fun onRechargeRequired(data: RechargeData) = onRechargeRequired.invoke(data)
    fun onChatConsultation(
        expert_id: String,
        name: String,
        image: String,
        skill: String,
        duration: String,
        category: String,
        chatType: String
    ) = onChatConsultation.invoke(expert_id, name, image, skill, duration, category, chatType)

    fun onChatCardClick(chat_id: String, name: String, image: String, duration: String) =
        onChatCardClick.invoke(chat_id, name, image, duration)

    init {
        lifecycle.doOnStart {
            Logger.d { "Chat History Screen Component onStart" }
            coroutineScope.launch {
                loadNextPage()
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Chat History Component onDestroy" }
            coroutineScope.cancel()
        }
    }

//    private suspend fun loadNextPage() {
//        if (isLoading || isLastPage) return
//
//        isLoading = true
//        Logger.d { "Loading page: $currentPage" }
//
//        val request = ChatHistoryListRequest(page = currentPage)
//
//        try {
//            when (val response = api.getAllChatHistoryList(request)) {
//                is Resources.Error -> {
//                    Logger.d { "Error fetching chat history: ${response.msg}" }
//                    isLoading = false
//                }
//                is Resources.Loading -> {
//                    Logger.d { "Loading: ${response.isLoading}" }
//                }
//                is Resources.Success -> {
//                    val chatList = response.data?.chatList ?: emptyList()
//                    Logger.d { "Fetched chat history: ${response.data}" }
//
//                    if (chatList.isEmpty()) {
//                        isLastPage = true
//                    } else {
//                        val newChatHistory = _state.value + response.data
//                        _state.value = newChatHistory
//                        currentPage++
//                    }
//                    isLoading = false
//                }
//            }
//        } catch (e: Exception) {
//            Logger.e { "Exception occurred while loading chat history: ${e.message}" }
//            isLoading = false
//        }
//    }

    private suspend fun loadNextPage() {
        if (isLoading || isLastPage) return

        isLoading = true
        Logger.d { "Loading page: $currentPage" }

        val request = ChatHistoryListRequest(page = currentPage)

        try {
            when (val response = api.getAllChatHistoryList(request)) {
                is Resources.Error -> {
                    Logger.e { "Error fetching chat history: ${response.msg}" }
                    isLoading = false
                }

                is Resources.Loading -> {
                    Logger.d { "Loading: ${response.isLoading}" }
                }

                is Resources.Success -> {
                    val chatList = response.data?.chatList ?: emptyList()
                    Logger.d { "Fetched chat history: ${response.data}" }

                    if (chatList.isEmpty()) {
                        isLastPage = true
                        Logger.d { "No more chat history pages to load." }
                    } else {
                        // Ensure that the chatList is of the correct type
                        Logger.d { "Loaded page $currentPage with ${chatList.size} items." }

                        // Safely initialize current state and append new data
                        val currentChatHistory =
                            _state.value ?: emptyList() // Ensure _state is not null
                        val newChatHistory =
                            currentChatHistory + response.data // Append new chat list

                        // Log the current and new states for debugging
                        Logger.d { "Current chat history size: ${currentChatHistory.size}" }
                        Logger.d { "New chat list size: ${chatList.size}" }
                        Logger.d { "New combined chat history size: ${newChatHistory.size}" }

                        // Update the state
                        _state.value =
                            newChatHistory as List<ChatHistoryListData> // Directly assign the new list

                        currentPage++
                        Logger.d { "Total chat items after loading: ${_state.value.size}." }
                    }
                    isLoading = false
                }
            }
        } catch (e: Exception) {
            Logger.e { "Exception occurred while loading chat history: ${e.message}" }
            e.printStackTrace() // Ensure you print the stack trace for debugging
            isLoading = false
        }
    }


    fun deleteChatMessage(chat_id: String) {
        coroutineScope.launch {
            try {
                when (val response = api.performDeleteChatMessage(chat_id)) {
                    is Resources.Error -> {
                        Logger.d { "deleteChatMessage ${response.msg}" }
                    }

                    is Resources.Loading -> {
                        Logger.d { "deleteChatMessage ${response.isLoading}" }
                    }

                    is Resources.Success -> {
                        onDeleteChatMessageSuccess(response.data!!)
                        Logger.d { "deleteChatMessage ${response.data}" }
                    }
                }
            } catch (e: Exception) {
                Logger.e { "Exception occurred while deleting chat message: ${e.message}" }
            }
        }
    }

    private fun onDeleteChatMessageSuccess(data: Boolean) {
        if (data) {
            showToast("Chat Delete Successful")
            coroutineScope.launch {
                currentPage = 1
                isLastPage = false
                _state.value = emptyList()
                loadNextPage()
            }
        }
    }

    // This method can be called on scroll or a trigger to load more data
    fun loadMoreChatHistory() {
        coroutineScope.launch {
//            currentPage += 1
//            isLastPage = false
//            _state.value = emptyList()
            loadNextPage()
        }
    }


    suspend fun postExpertReview(request: RequestReviewSubmit) {
        when (val response = api.postSubmitExpertReview(request)) {
            is Resources.Error -> {

            }

            is Resources.Loading -> {
                // _summitReviewState.emit(SubmitExpertReviewState.Loading(resource.isLoading))
            }

            is Resources.Success -> {
//                    val updatedDetails =
//                        ExpertDetailsApiService().requestExpertDetailsInfo(request.consultantId)
                Logger.d(tag = "postExpertReview", messageString = "Success: ${response.data}")
//                    _summitReviewStateConfirmation.value = resource.data!!
                onPostExpertReviewSuccess(response.data ?: false)
            }
        }

    }

    private fun onPostExpertReviewSuccess(data: Boolean) = coroutineScope.launch {
        showToast("Review Submit Successful")
//        _summitReviewState.emit(SubmitExpertReviewState.Success(data))
    }
}
