package tta.destinigo.talktoastro.feature_profile.refer_and_earn

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.data.response.WeeklyWinners
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.widget.FAQScreen
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.widget.ShareCodeScreen
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.widget.WeeklyTopWinners
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.coin_balance
import tta.destinigo.talktoastro.resources.refer_earn_big_banner
import tta.destinigo.talktoastro.resources.refer_earn_img
import tta.destinigo.talktoastro.shared.local.UserInfoProvider.getTotalCoin
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.primaryColor

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ReferEarnScreen(component: ReferAndEarnScreenComponent) {
    val state by component.state.collectAsState()
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_COMPACT
    }
    Scaffold(
        modifier = Modifier
            .fillMaxSize()
            .background(Color.White),
        topBar = { TopBar(component) },
        bottomBar = { ShareBottomSheet(component) }
    ) { paddingValues ->
        Box(Modifier.fillMaxSize().background(background_color), contentAlignment = Alignment.Center,) {
            if(state.isEmpty()){
                Text(
                    text = "No Data available.",
                    color = Color.Gray,
                    //modifier = Modifier.align(Alignment.CenterHorizontally)
                )
            }else{
                LazyColumn(
                    modifier = widthModifier
                        .padding(paddingValues)
                        .fillMaxSize()
                ) {
                    item {
                        Spacer(modifier = Modifier.height(60.dp))
                        ReferEarnContent(state)

                    }
                }
            }

        }

    }
}

@Composable
fun TopBar(component: ReferAndEarnScreenComponent) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .background(Color.White)
            //.elevation(4.dp)
            .padding(horizontal = 8.dp, vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        IconButton(onClick = { component.onBackClick() }) {
            Icon(
               imageVector = Icons.Default.ArrowBack,
                contentDescription = "Back Button",
                modifier = Modifier.size(25.dp)
            )
        }
        Text(
            text = "Refer n Earn",
            style = MaterialTheme.typography.bodyLarge.copy(fontWeight = FontWeight.Bold),
            modifier = Modifier.weight(1f)
        )

        CoinBalanceSection()
    }
}

@Composable
fun CoinBalanceSection() {
    Row(
        modifier = Modifier
            .background(
                color = primaryColor,
                shape = CircleShape
            )
            .padding(4.dp)
            .padding( end = 16.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier.padding(start = 8.dp)
                .size(20.dp)
                .background(Color.White, shape = CircleShape),
            contentAlignment = Alignment.Center
        ) {
            // TODO Need to change Icon
            Icon(
                painter = painterResource(Res.drawable.coin_balance),
                contentDescription = null,
                tint = Color.Unspecified,
                modifier = Modifier.padding(4.dp)
            )
        }
        Column(
            modifier = Modifier.padding(start = 6.dp)
        ) {
            val currentCoin = getTotalCoin()
            Text(text = "Wallet Coin", fontSize = 16.sp, color = Color.White)
            Text(
                text = currentCoin.toString(), // Replace with actual coin balance
                fontSize = 14.sp,
                textAlign = TextAlign.End,
                style = MaterialTheme.typography.bodySmall.copy(fontWeight = FontWeight.Bold,color = Color.White)
            )
        }
    }
}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ReferEarnContent(data: List<WeeklyWinners>) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp)
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Column(
                modifier = Modifier.weight(1f), // Allow the column to take up available space
                verticalArrangement = Arrangement.Center
            ) {
                Text(
                    text = "Get Rewarded for Sharing",
                    fontSize = 20.sp,
                    maxLines = 2,
                    style = MaterialTheme.typography.bodyLarge.copy(fontWeight = FontWeight.Bold),
                    modifier = Modifier.padding(bottom = 8.dp) // Add spacing between text
                )

                Text(
                    text = "Invite your friends to Winyway app, get rewarded when they join and win free consultation.",
                    fontSize = 16.sp,
                    maxLines = 3,
                    style = MaterialTheme.typography.bodyMedium
                )
            }

            Spacer(modifier = Modifier.width(16.dp)) // Add space between column and image

            Image(
                painter = painterResource(Res.drawable.refer_earn_big_banner),
                contentDescription = null,
                modifier = Modifier
                    .height(IntrinsicSize.Min),
                contentScale = ContentScale.Crop
            )
        }


        if(windowSizeClass == WindowWidthSizeClass.Compact){
            Image(
                painter = painterResource(Res.drawable.refer_earn_img),
                contentDescription = null,
                modifier = Modifier
                    .fillMaxWidth(),
                contentScale = ContentScale.Crop

            )
        }else{
            Image(
                painter = painterResource(Res.drawable.refer_earn_img),
                contentDescription = null,
                modifier = Modifier
                    .fillMaxWidth(),
                contentScale = ContentScale.Fit

            )
        }

    }

    WeeklyWinnerStub(data)
    ReferEarnFAQ()
    Spacer(modifier = Modifier.height(100.dp))
}

@Composable
fun WeeklyWinnerStub(data: List<WeeklyWinners>) {
    // Replace with your actual layout/stub
    Box(
        modifier = Modifier
            .fillMaxWidth()
            //.height(100.dp)
            .background(Color.LightGray),
        contentAlignment = Alignment.Center
    ) {
        WeeklyTopWinners(data)
        //Text(text = "Weekly Winner Layout Stub")
    }
}

@Composable
fun ReferEarnFAQ() {
    // Replace with your actual layout
    Box(
        modifier = Modifier
            .fillMaxWidth()
            //.background(Color.White)
            .padding(16.dp)
    ) {
        FAQScreen()
        //Text(text = "FAQ Section Placeholder")
    }
}

@Composable
fun ShareBottomSheet(component: ReferAndEarnScreenComponent) {
    // Replace with your Bottom Sheet implementation
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .background(primaryColor.copy(alpha = 0.7f))
        //.padding(16.dp)
    ) {

        ShareCodeScreen(component = component)
    }
}
